function UserLessonInput() {

    this.userLessonInputId = null
    this.userLessonId = null
    this.key = null
    this.isTheCorrectKey = null
    this.keyDownIn = null

}

export default UserLessonInput